var SmoothScroll = ( function () {
    if (window.location.hash) { setTimeout(function () { window.scrollTo(0, 0); }, 1); }

    var $anchorLinks = $( 'a[href*="#"]' ).not( 'a[href="#"]' );

    function goTo(target) {
        if (target === "" || !$(target).length) { return; }
        var scrollPos = typeof target === 'number' ? target : $(target).offset().top;

        if (window.innerWidth >= 720) {
            scrollPos -= $('header').outerHeight();
        } else {
            scrollPos -= $('header').outerHeight() * 2;
        }

        $('html, body').stop().animate({
            'scrollTop': scrollPos - 32
        }, 1200, 'swing', function () {
            if (typeof target === 'string') {

                if (window.location.hash) {
                    // window.location.hash = target;
                }
            }
        });
    }

    if (window.location.hash) {
        setTimeout(function () {
            goTo(window.location.hash);
        }, 500);
    }

    if ($anchorLinks.length) {
        $anchorLinks.on('click', function (e) {
            if (!$("#" + this.hash.replace('#', '')).length) { return; }
            e.preventDefault();
            goTo(this.hash);
        });
    }

    return { to: goTo }
}());