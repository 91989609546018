var StickyHeader = (function () {
    var header = document.querySelector('.header');

    function makeSticky(bool) {
        var isSticky = typeof bool == 'undefined' ? true : bool;
        if (isSticky) {
            header.classList.add('sticky');
            document.body.style.marginTop = header.clientHeight + 'px';
        } else {
            header.classList.remove('sticky');
            document.body.style.marginTop = 0;
        }
    }

    window.onscroll = function () {
        if (this.pageYOffset > 6) {
            makeSticky(true);
        } else {
            makeSticky(false);
        }
    }
}() );

var HeroSlider = ( function () {
    var $section = $( '.hero-slider' );
    if ( !$section.length ) { return; }

    var $slider = $section.find( '.slider' );
    $slider.slick( {
        mobileFirst: true,
        slidesToShow: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        appendDots: $section.find( '.slider-dots' ),
    });
 }() );