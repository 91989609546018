var NavMenu = (function () {
    var burgerMenu = document.querySelector('.burger-menu');
    var navMenu = document.querySelector('.header-nav .menu');

    function openMenu() {
        this.classList.add('close');
        navMenu.classList.add('expand')
        $('body').addClass('no-scroll');
    }

    function closeMenu() {
        this.classList.remove('close');
        navMenu.classList.remove('expand')
        $('body').removeClass('no-scroll');
    }

    function toggleMenu() {
        this.classList.contains('close') ? closeMenu.apply(this) : openMenu.apply(this);
    }

    burgerMenu.addEventListener('click', toggleMenu);
    navMenu.addEventListener('click', function (e) {
        closeMenu.apply(burgerMenu);
    });
}());